import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import logo2 from './dagfakta.png';
import './App.css';
import geo from './geography_facts_365.json';
import fakta from './snabbfakta.json';
// import his from './recent_history_facts_365.json';
// import pol from './political_facts_365.json';

function App() {

  const [geoFact, setGeoFact] = useState<any>(null)
  const [hisFact, setHisFact] = useState<any>(null)
  const [polFact, setPolFact] = useState<any>(null)
  const [date, setDate] = useState<any>(new Date().toLocaleString().split(' ')[0]);

  useEffect(() => {
    getFact();
  }, [])

  const getFact = () => {
    var d = new Date("2024-03-22");
    var d2 = new Date("2024-04-12");
    var dNow = new Date();
    if (dNow > d && dNow < d2) {
      for (let i = 0; i < fakta.length; i++) {
        const element = fakta[i];
        if (element.date == date) {
          setGeoFact(element);
          break;
        }
      }
    }
    else {
      for (let i = 0; i < geo.length; i++) {
        const element = geo[i];
        if (element.date == date) {
          setGeoFact(element);
          break;
        }
      }
    }


    // for (let i = 0; i < fakta.length; i++) {
    //   const element = fakta[i];
    //   if (element.date == date) {
    //     setGeoFact(element);
    //     break;
    //   }
    // }
    // for (let i = 0; i < his.length; i++) {
    //   const element = his[i];
    //   if (element.date == date) {
    //     setHisFact(element);
    //     break;
    //   }
    // }
    // for (let i = 0; i < pol.length; i++) {
    //   const element = pol[i];
    //   if (element.date == date) {
    //     setPolFact(element);
    //     break;
    //   }
    // }
  }
  const addDay = () => {
    var result = new Date(date);
    result.setDate(result.getDate() + 1);
    setDate(result.toLocaleString().split(' ')[0]);
    getFact();
  }
  return (
    <div className="App">
      <header className="App-header" style={{ padding: '50px 20px' }}>
        <img src={logo2} style={{width:'260px'}}/>
        <h1>Dagens fakta</h1>
        {geoFact != null &&
          <div style={{ maxWidth: '650px' }}>
            <h3>{date}</h3>

            {/* <h2>{geoFact.category}</h2> */}
            <p>{geoFact.description}</p>
          </div>
        }

        {/* <button onClick={addDay} style={{padding:'5px 20px', borderRadius:'20px'}}>Nästa dag</button> */}
      </header>
    </div>
  );
}

export default App;
